body {
	--back: #fff;
	--color: #333;
}

body.dark {
	--back: #333;
	--color: #fff;
}

body {
	color: var(--color);
	background-color: var(--back);
}

.notch {
	display: none;
}

.iosPWA .notch {
	pointer-events: none;
	height: 20px;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: space-between;
	top: 0;
	z-index: 999999999999999;
}

svg {
	display: block;
}

.Home {
	display: flex;
	min-height: 100vh;
}

.Home-login {
	position: relative;
	max-width: 600px;
	width: 100%;
	padding: 64px 48px;
}

.Home-login .logo + .text {
	margin-top: 16px;
}

.Home-login .title {
	font-size: 36px;
	font-weight: 700;
	line-height: 1;
}

.Home-login .subtitle {
	opacity: 0.8;
	font-size: 16px;
}

.Home-hero {
	width: 100%;
	background-color: #333;
	cursor: pointer;
	min-height: 100%;
	user-select: none;
}

.dark .Home-hero {
	background-color: #4885fb;
}

.form {
	margin-top: 32px;
}

.field {
	position: relative;
}

.form .input {
	border: none;
	padding: 16px;
	width: 100%;
	font-size: 16px;
	outline: none !important;
	background-color: var(--back);
	color: var(--color);
}

select.input {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	background-position: calc(100% - 16px);
	background-size: 24px;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" fill="rgba(128,128,128,.5)"/></svg>');
}

.form .field:not(:last-of-type) {
	border-bottom: 1px rgba(128, 128, 128, .28) solid;
}

.notify {
	position: fixed;
	top: 0;
	left: 0;
	margin: 8px;
	width: calc(100% - 16px);
	background-color: #ff5252;
	color: #fff;
	padding: 16px;
	white-space: nowrap;
	border-radius: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	cursor: pointer;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .28);
	transform: translateY(-100%);
	opacity: 0;
	transition: transform ease-out .3s, opacity ease-out .3s;
}

.notify.notify-show {
	transform: none;
	opacity: 1;
}

.switcher {
	display: flex;
	align-items: center;
}

.switcher .separator {
	margin: 8px;
	opacity: 0.5;
	width: fit-content;
	white-space: nowrap;
	font-size: 14px;
}

.switcher .switch {
	padding: 16px;
	cursor: pointer;
	margin: 8px;
	width: 100%;
	opacity: 0.7;
	border-radius: 4px;
	text-align: center;
	border: 2px solid transparent;
	transition: color ease-out .2s, border-color ease-out .2s, transform ease-in-out .2s;
}

.switcher .switch:active {
	transform: scale(0.96);
}

.switcher .switch.active {
	color: #4885fb;
	font-weight: 600;
	opacity: 1;
	border: 2px solid #4885fb;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.footer .powered{
	text-align: center;
	margin-top: 8px;
	font-size: 14px;
	opacity: 0.8;
}

.footer .powered a{
	display: inline-block;
}

.footer-wrap{
	display: flex;
	justify-content: space-between;
}

.Home-login .footer {
	bottom: 16px;
	width: calc(100% - 96px);
}

.footer .theme,
.footer .locale {
	cursor: pointer;
	font-weight: 700;
	user-select: none;
}

.footer .locale{
	line-height: 24px;
}

.footer a{
	display: block;
	text-decoration: none;
}

.footer a svg{
	height: 24px;
	width: 24px;
	fill: var(--color);
}

.footer .theme {
	width: 24px;
}

.loader {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: var(--back);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 101;
	opacity: 0;
	pointer-events: none;
	cursor: progress;
	transition: opacity .2s ease-in-out;
}

.loading.loader {
	opacity: 0.8;
	pointer-events: all;
}

.loader .loader-element {
	position: relative;
	height: 32px;
	width: 32px;
	border: 1px rgba(128, 128, 128, .14) solid;
	border-radius: 50%;
	animation: loading .8s cubic-bezier(0.6, 0.2, 0.1, 1) infinite;
}

.loader .loader-element::after {
	content: "";
	display: block;
	position: absolute;
	top: -3px;
	height: 6px;
	width: 6px;
	left: 13px;
	background-color: var(--color);
	border-radius: 3px;
}

.button {
	appearance: none;
	border: none;
	background-color: #4885fb;
	color: #fff;
	box-shadow: 0 8px 16px -4px rgba(72, 133, 251, .28);
	font-weight: 600;
	padding: 16px 32px;
	text-align: center;
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;
	text-transform: uppercase;
	outline: none !important;
	transition: background-color .2s ease-in-out;
}

.button:focus {
	background-color: rgb(110, 158, 255);
	box-shadow: 0 4px 8px -2px rgba(72, 133, 251, .32);
}

.button:active {
	background-color: rgb(56, 122, 255);
	box-shadow: 0 4px 8px -2px rgba(72, 133, 251, .28);
}

.form .button {
	width: calc(100% - 16px);
	margin: 8px;
}

@media (max-width: 926px) {
	.Home-login {
		max-width: 50%;
	}

	.Home-hero {
		width: 50%;
	}
}

@media (max-width: 768px) {
	.Home-login {
		max-width: 100%;
		padding: 64px 32px 96px 32px;
		min-height: calc(100vh - 100px);
	}

	.Home-login .footer {
		width: calc(100% - 64px);
	}

	.Home {
		flex-direction: column-reverse;
		justify-content: flex-end;
	}

	.Home-hero {
		min-height: 100px;
		width: 100%;
	}
	.iosPWA .Home-login {
		margin-top: 50px;
	}

	.iosPWA .Home-hero {
		position: fixed;
		min-height: 50px;
	}
}


.info-modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
  transition: opacity .1s ease-out;
}

.info-modal.active{
  opacity: 1;
  pointer-events: all;
}

.info-modal__backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	background-color: rgba(128, 128, 128, .14);
}

.info-modal__content {
  position: relative;
	background-color: var(--back);
	border-radius: 8px;
  max-width: calc(100% - 40px);
  width: 400px;
  max-height: calc(100% - 40px);
  transition: transform .1s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 4px 16px -2px rgba(0,0,0, .28);
  transform-origin: center center;
  overflow: hidden;
}

.info-modal__content__inner{
  padding: 24px;
}

.info-modal__content__back{
  position: absolute;
  z-index: -1;
  width: 35%;
  transform: rotate(-48deg);
  right: 0;
  bottom: -20px;
  opacity: 0.2;
  transition: transform .3s ease-out;
}

.info-modal.active .info-modal__content__back{
  transform: rotate(-24deg);
}


.info-modal__content__back svg{
  fill: var(--color);
}

.info-modal .info-modal__content{
  transform: translate(-50%, -50%) scale(1.2);
}

.info-modal.active .info-modal__content{
  transform: translate(-50%, -50%) scale(1);
}

.info-modal__content h2{
  margin-bottom: 8px;
}

@keyframes loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

#yandex_rtb_R-A-329971-1 {
	display: flex;
	justify-content: center;
}

.adds {
	margin: 24px 0;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 14px;
	color: rgba(128, 128, 128, .8);
	padding-bottom: 8px;
	opacity: 0.8;
	border-bottom: 1px rgba(128, 128, 128, .3) solid;
}
