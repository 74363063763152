.Dashboard {
    max-width: 940px;
    padding: 0 20px 24px 20px;
    margin: auto;
}

.appbar-wrap{
    max-width: 900px;
    margin: auto;
}

.Dashboard .footer {
    position: unset;
    margin-top: 32px;
}

.header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.header h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 40px);
}

.header .title h1{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 36px);
}

.header-avatar {
    height: 48px;
    cursor: pointer;
    display: block;
}

.shown .subject {
    -webkit-animation: none;
            animation: none;
}

.subject .title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subject {
    position: relative;
    -webkit-animation-name: revealLeaders;
            animation-name: revealLeaders;
    -webkit-animation-duration: .4s;
            animation-duration: .4s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

.attached {
    display: flex;
    border: 2px rgba(128, 128, 128, .5) dashed;
    border-radius: 8px;
    flex-wrap: wrap;
}

.homework .info {
    margin-bottom: 4px;
}

.content {
    margin-top: 24px;
}

.subject.jko {
    padding: 12px 0;
    margin: 12px 0;
}

.subject.imko {
    padding: 16px 0 24px 0;
}

.subject:not(:last-of-type) {
    border-bottom: 1px solid rgba(128, 128, 128, .28);
}


.subject .bar {
    margin: 8px 0;
}

.bar {
    height: 4px;
    border-radius: 2px;
}

.subject .bar.ok {
    background-color: #83c952;
    box-shadow: 0 4px 16px 0 rgba(131, 201, 82, .14);
}

.subject .bar.norm {
    background-color: #eeac45;
    box-shadow: 0 4px 16px 0 rgba(238, 172, 69, .14)
}

.subject .bar.bad {
    background-color: #d3584b;
    box-shadow: 0 4px 16px 0 rgba(211, 88, 75, .14)
}

.subject .title {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
}

.subject .value {
    font-size: 28px;
    font-weight: 700;
}

.imko .progress {
    display: flex;
}

.imko .progress .bar {
    font-size: 13px;
    font-weight: 600;
    line-height: 2.5;
    white-space: nowrap;
}

.goals+.homework {
    box-shadow: 0 -1px 0 0 rgba(128, 128, 128, .14);
    margin-top: 16px;
    padding-top: 16px;
}

.goals .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.goals .goal-name {
    align-items: center;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
    border-radius: 3px;
    border: 1px #ababab solid;
}

.status {
    align-items: center;
    border-radius: 3px;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
}

.goal-content {
    margin-top: 5px;
    font-size: 14px;
    word-wrap: break-word;
    word-break: break-word;
}

.status.ok {
    background-color: #83c952 !important;
    color: #fff !important;
}

.date {
    font-size: 13px;
}

.goal,
.work {
    padding: 16px 8px;
    cursor: default;
}

.work.rubric{
    cursor: pointer;
}

.work.rubric:hover{
    opacity: 0.7;
}

.goal:not(:last-of-type),
.work:not(:last-of-type) {
    box-shadow: 0 1px 0 0 rgba(128, 128, 128, .14);
}

.imko .progress .bar.fa {
    margin-right: 5px;
}

.imko .progress .bar {
    min-width: 56px;
}

.file {
    margin: 5px;
    width: 50px;
    height: 50px;
    padding: 12px;
    color: var(--back);
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    background-color: var(--color);
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.file:hover {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.file:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9)
}

.empty {
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    color: rgba(128, 128, 128, .14);
    font-weight: bold;
    border: 2px rgba(128, 128, 128, .14) dashed;
}

.grade {
    position: relative;
}

.grade .percentage {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    font-size: 16px;
}

.child-select {
    position: relative;
}

.child-select__selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.child-select__selected svg {
    margin-left: 4px;
}

.child-modal {
    opacity: 0;
    padding: 20px;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.child-modal__menu {
    text-align: center;
    max-width: 320px;
    width: 100%;
    background: var(--back);
    z-index: 100;
    border-radius: 16px;
    overflow: hidden;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    box-shadow: 0 8px 16px -2px rgba(128, 128, 128, .14);
}

.child-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, .14);
    z-index: -1;
}

.period-select .bar {
    position: absolute;
    width: 25%;
    background-color: var(--color);
    bottom: 0;
    height: 3px;
    border-radius: 3px 3px 0px 0px;
    -webkit-transition: margin-left .3s cubic-bezier(.6, .2, .1, 1), background-color .3s ease-in-out;
    transition: margin-left .3s cubic-bezier(.6, .2, .1, 1), background-color .3s ease-in-out;
}

.dark .period-select .bar{
    background-color: #4885fb;
}

.period-select {
    display: flex;
    position: relative;
    margin-top: 8px;
}

.period-select__quarter {
    text-align: center;
    text-transform: lowercase;
    width: 25%;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    opacity: 0.7;
}

.period-select__quarter.selected {
    opacity: 1;
    font-weight: 700;
}
.dark .period-select__quarter.selected{
    color: #4885fb
}

.active .child-modal__menu {
    -webkit-transform: none;
            transform: none;
}

.child-select__child {
    padding: 0 16px;
    line-height: 64px;
    overflow: hidden;
    -webkit-transition: background-color .2s ease-in-out, color .2s ease-in-out;
    transition: background-color .2s ease-in-out, color .2s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.child-select__child:not(:last-of-type) {
    box-shadow: 0 1px 0 0 rgba(128, 128, 128, .14);
}

.child-select__child.selected::before {
    display: inline-block;
    content: '';
    height: 6px;
    width: 6px;
    background-color: var(--color);
    margin-right: 6px;
    margin-bottom: 1px;
    border-radius: 3px;
    -webkit-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
}

.child-select__child.selected:hover::after {
    background-color: var(--back);
}

.child-modal.active {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: none;
            transform: none;
}

.child-select__child:hover {
    background-color: var(--color);
    color: var(--back);
    cursor: pointer;
}

.goals-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--back);
    height: 100vh;
    width: 100vw;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    -webkit-transition: opacity .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: opacity .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    z-index: 99990;
}

.goals-modal__header {
    height: 48px;
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    box-shadow: 0 1px 0 0 rgba(128, 128, 128, .14);
    display: flex;
    justify-content: space-between;
}

.goals-modal__title {
    max-width: calc(100% - 48px);
    padding-left: 24px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.goals-modal__close {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.goals-modal__close:hover {
    background-color: rgba(128, 128, 128, .14);
}

.goals-modal__close svg {
    display: block;
    opacity: 0.7;
}

.goals-modal.active {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    pointer-events: all;
}

.goals-modal__content {
    max-width: 940px;
    padding: 32px;
    margin: 0 auto;
    overflow: auto;
    box-sizing: border-box;
    height: calc(100% - 48px);
    -webkit-overflow-scrolling: touch;
}

.goals-homework .name {
    font-size: 14px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.goals-homework .title {
    margin-bottom: 8px;
}

.goals-homework .work:last-of-type {
    margin-bottom: 0;
}

.homework:not(:last-of-type) {
    box-shadow: 0 1px 0 0 rgba(128, 128, 128, .14);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.goals-modal.active .goals-modal__content {
    -webkit-transform: none;
            transform: none;
}

.status.bad {
    background-color: #FFC107;
}

.back {
    cursor: pointer;
    margin-right: 8px;
}

.header .title{
    display: flex;
    align-items: center;
    overflow: hidden;
}

.back:hover{
    opacity: .7;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-hidden.ios {
    position: fixed;
}

.clickable {
    cursor: pointer;
}

.recaptcha{
    padding: 16px 0 8px 0;
    display: none;
}

.recaptcha.active{
    display: flex;
    justify-content: center;
}

.subject.imko::after {
    white-space: nowrap;
    content: ' ';
    font-size: 12px;
    position: absolute;
    display: block;
    height: 20px;
    width: 1px;
    background-color: rgba(128, 128, 128, 0.28);
    left: calc(80% + 5px);
    bottom: 0;
}

@media (max-width: 475px) {
    .header h1 {
        font-size: 28px;
    }
}

.rubric-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
    pointer-events: none;
    z-index: 100000;
}

.rubric-modal.active{
    opacity: 1;
    pointer-events: all;
}

.rubric-modal .modal-backdrop{
    background-color: rgba(128,128,128,.14);
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.rubric-modal .modal-content{
    background-color: var(--back);
    max-width: 920px;
    margin: 16px;
    border-radius: 8px;
    padding: 24px;
    z-index: 1000000;
}

.rubric-criterion{
    font-weight: 600;
    font-size: 20px
}
.rubric-results {
    display: flex;
    padding: 16px 0;
}

.close{
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px;
    height: 32px;
    opacity: 0.7;
    cursor: pointer;
}

.close:hover{
    opacity: 0.5;
}

.rubric-result .rubric:not(:last-of-type){
    border-bottom: 1px rgba(128,128,128,.28) solid;
    margin-bottom: 16px;
}

.rubric-results>div{
    font-size: 14px;
    padding: 4px 8px;
    margin: 4px 0;
    border-radius: 4px;
    width: 33.33%;
    border: 1px solid transparent;
}

.rubric-results>.LowDescriptor.selected{
    border-color: #d3584b;
}

.rubric-results>.MediumDescriptor.selected{
    border-color: #eeac45;
}

.rubric-results>.HighDescriptor.selected{
    border-color: #83c952;
}

.rcs-custom-scroll .rcs-inner-handle{
    background-color: var(--color)!important;
    opacity: 0.7;
}

.appbar{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 16px 20px 0 20px;
    background-color: var(--back);
    z-index: 1000;
    -webkit-transition: box-shadow .3s ease-out;
    transition: box-shadow .3s ease-out;
}

.header.goals{
    padding-top: 16px;
}

.scrolled .appbar{
    box-shadow: 0 1px 0 0 rgba(128,128,128,.28);
}

.subjects{
    padding-top: 96px;
}

.subjects.parent{
    padding-top: 114px;
}

@media (max-width: 768px){
    .rubric-results{
        flex-wrap: wrap;
        text-align: center;
    }
    .rubric-results>div{
        width: 100%;
    }
    .rubric-modal{
        display: block;
    }
    .rubric-modal .modal-content{
        margin: 0;
        padding: 64px 0 0 0;
        height: 100%;
        overflow: hidden;
        border-radius: 0;
    }
    .rubric-modal .modal-content::after{
        display: block;
        position: absolute;
        top: 64px;
        width: 100%;
        left: 0;
        height: 1px;
        background-color: rgba(128, 128, 128, .28);
        content: '';
    }
    .rubric-result{
        padding: 24px;
    }
    .rubric-criterion{
        font-size: 16px;
    }
}

@-webkit-keyframes revealLeaders {
    from {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }

    to {
        -webkit-transform: none;
                transform: none;
        opacity: 1;
    }
}

@keyframes revealLeaders {
    from {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }

    to {
        -webkit-transform: none;
                transform: none;
        opacity: 1;
    }
}

@-webkit-keyframes barLoad {
    from {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }

    to {
        -webkit-transform: scaleX(1);
                transform: scaleX(1)
    }
}

@keyframes barLoad {
    from {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }

    to {
        -webkit-transform: scaleX(1);
                transform: scaleX(1)
    }
}
body {
	--back: #fff;
	--color: #333;
}

body.dark {
	--back: #333;
	--color: #fff;
}

body {
	color: var(--color);
	background-color: var(--back);
}

.notch {
	display: none;
}

.iosPWA .notch {
	pointer-events: none;
	height: 20px;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: space-between;
	top: 0;
	z-index: 999999999999999;
}

svg {
	display: block;
}

.Home {
	display: flex;
	min-height: 100vh;
}

.Home-login {
	position: relative;
	max-width: 600px;
	width: 100%;
	padding: 64px 48px;
}

.Home-login .logo + .text {
	margin-top: 16px;
}

.Home-login .title {
	font-size: 36px;
	font-weight: 700;
	line-height: 1;
}

.Home-login .subtitle {
	opacity: 0.8;
	font-size: 16px;
}

.Home-hero {
	width: 100%;
	background-color: #333;
	cursor: pointer;
	min-height: 100%;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.dark .Home-hero {
	background-color: #4885fb;
}

.form {
	margin-top: 32px;
}

.field {
	position: relative;
}

.form .input {
	border: none;
	padding: 16px;
	width: 100%;
	font-size: 16px;
	outline: none !important;
	background-color: var(--back);
	color: var(--color);
}

select.input {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	background-position: calc(100% - 16px);
	background-size: 24px;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" fill="rgba(128,128,128,.5)"/></svg>');
}

.form .field:not(:last-of-type) {
	border-bottom: 1px rgba(128, 128, 128, .28) solid;
}

.notify {
	position: fixed;
	top: 0;
	left: 0;
	margin: 8px;
	width: calc(100% - 16px);
	background-color: #ff5252;
	color: #fff;
	padding: 16px;
	white-space: nowrap;
	border-radius: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	cursor: pointer;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .28);
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	opacity: 0;
	-webkit-transition: opacity ease-out .3s, -webkit-transform ease-out .3s;
	transition: opacity ease-out .3s, -webkit-transform ease-out .3s;
	transition: transform ease-out .3s, opacity ease-out .3s;
	transition: transform ease-out .3s, opacity ease-out .3s, -webkit-transform ease-out .3s;
}

.notify.notify-show {
	-webkit-transform: none;
	        transform: none;
	opacity: 1;
}

.switcher {
	display: flex;
	align-items: center;
}

.switcher .separator {
	margin: 8px;
	opacity: 0.5;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	white-space: nowrap;
	font-size: 14px;
}

.switcher .switch {
	padding: 16px;
	cursor: pointer;
	margin: 8px;
	width: 100%;
	opacity: 0.7;
	border-radius: 4px;
	text-align: center;
	border: 2px solid transparent;
	-webkit-transition: color ease-out .2s, border-color ease-out .2s, -webkit-transform ease-in-out .2s;
	transition: color ease-out .2s, border-color ease-out .2s, -webkit-transform ease-in-out .2s;
	transition: color ease-out .2s, border-color ease-out .2s, transform ease-in-out .2s;
	transition: color ease-out .2s, border-color ease-out .2s, transform ease-in-out .2s, -webkit-transform ease-in-out .2s;
}

.switcher .switch:active {
	-webkit-transform: scale(0.96);
	        transform: scale(0.96);
}

.switcher .switch.active {
	color: #4885fb;
	font-weight: 600;
	opacity: 1;
	border: 2px solid #4885fb;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.footer .powered{
	text-align: center;
	margin-top: 8px;
	font-size: 14px;
	opacity: 0.8;
}

.footer .powered a{
	display: inline-block;
}

.footer-wrap{
	display: flex;
	justify-content: space-between;
}

.Home-login .footer {
	bottom: 16px;
	width: calc(100% - 96px);
}

.footer .theme,
.footer .locale {
	cursor: pointer;
	font-weight: 700;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.footer .locale{
	line-height: 24px;
}

.footer a{
	display: block;
	text-decoration: none;
}

.footer a svg{
	height: 24px;
	width: 24px;
	fill: var(--color);
}

.footer .theme {
	width: 24px;
}

.loader {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: var(--back);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 101;
	opacity: 0;
	pointer-events: none;
	cursor: progress;
	-webkit-transition: opacity .2s ease-in-out;
	transition: opacity .2s ease-in-out;
}

.loading.loader {
	opacity: 0.8;
	pointer-events: all;
}

.loader .loader-element {
	position: relative;
	height: 32px;
	width: 32px;
	border: 1px rgba(128, 128, 128, .14) solid;
	border-radius: 50%;
	-webkit-animation: loading .8s cubic-bezier(0.6, 0.2, 0.1, 1) infinite;
	        animation: loading .8s cubic-bezier(0.6, 0.2, 0.1, 1) infinite;
}

.loader .loader-element::after {
	content: "";
	display: block;
	position: absolute;
	top: -3px;
	height: 6px;
	width: 6px;
	left: 13px;
	background-color: var(--color);
	border-radius: 3px;
}

.button {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	border: none;
	background-color: #4885fb;
	color: #fff;
	box-shadow: 0 8px 16px -4px rgba(72, 133, 251, .28);
	font-weight: 600;
	padding: 16px 32px;
	text-align: center;
	font-size: 16px;
	border-radius: 4px;
	cursor: pointer;
	text-transform: uppercase;
	outline: none !important;
	-webkit-transition: background-color .2s ease-in-out;
	transition: background-color .2s ease-in-out;
}

.button:focus {
	background-color: rgb(110, 158, 255);
	box-shadow: 0 4px 8px -2px rgba(72, 133, 251, .32);
}

.button:active {
	background-color: rgb(56, 122, 255);
	box-shadow: 0 4px 8px -2px rgba(72, 133, 251, .28);
}

.form .button {
	width: calc(100% - 16px);
	margin: 8px;
}

@media (max-width: 926px) {
	.Home-login {
		max-width: 50%;
	}

	.Home-hero {
		width: 50%;
	}
}

@media (max-width: 768px) {
	.Home-login {
		max-width: 100%;
		padding: 64px 32px 96px 32px;
		min-height: calc(100vh - 100px);
	}

	.Home-login .footer {
		width: calc(100% - 64px);
	}

	.Home {
		flex-direction: column-reverse;
		justify-content: flex-end;
	}

	.Home-hero {
		min-height: 100px;
		width: 100%;
	}
	.iosPWA .Home-login {
		margin-top: 50px;
	}

	.iosPWA .Home-hero {
		position: fixed;
		min-height: 50px;
	}
}


.info-modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .1s ease-out;
  transition: opacity .1s ease-out;
}

.info-modal.active{
  opacity: 1;
  pointer-events: all;
}

.info-modal__backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	background-color: rgba(128, 128, 128, .14);
}

.info-modal__content {
  position: relative;
	background-color: var(--back);
	border-radius: 8px;
  max-width: calc(100% - 40px);
  width: 400px;
  max-height: calc(100% - 40px);
  -webkit-transition: -webkit-transform .1s ease-out;
  transition: -webkit-transform .1s ease-out;
  transition: transform .1s ease-out;
  transition: transform .1s ease-out, -webkit-transform .1s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 4px 16px -2px rgba(0,0,0, .28);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  overflow: hidden;
}

.info-modal__content__inner{
  padding: 24px;
}

.info-modal__content__back{
  position: absolute;
  z-index: -1;
  width: 35%;
  -webkit-transform: rotate(-48deg);
          transform: rotate(-48deg);
  right: 0;
  bottom: -20px;
  opacity: 0.2;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.info-modal.active .info-modal__content__back{
  -webkit-transform: rotate(-24deg);
          transform: rotate(-24deg);
}


.info-modal__content__back svg{
  fill: var(--color);
}

.info-modal .info-modal__content{
  -webkit-transform: translate(-50%, -50%) scale(1.2);
          transform: translate(-50%, -50%) scale(1.2);
}

.info-modal.active .info-modal__content{
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.info-modal__content h2{
  margin-bottom: 8px;
}

@-webkit-keyframes loading {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}

@keyframes loading {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}

#yandex_rtb_R-A-329971-1 {
	display: flex;
	justify-content: center;
}

.adds {
	margin: 24px 0;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 14px;
	color: rgba(128, 128, 128, .8);
	padding-bottom: 8px;
	opacity: 0.8;
	border-bottom: 1px rgba(128, 128, 128, .3) solid;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: #4885fb!important;
  font-weight: 700;
}
